<template>
  <div class="cardRelatorio">
    <b-row>
      <b-col lg="3">
        <b-card-header
          header="Contrato"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox v-model="optionsLocal.Financeiro" @change="marcarDesmarcarHandler('Financeiro')">
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroContratosConsulta">Consultar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroContratosPendentes">Contratos Pendentes</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroCancelarContrato">Cancelar Contrato</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroContratosConsolidados">Contratos Consolidados</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroAnexarArquivos">Anexar Arquivos</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroExcluirArquivos">Excluir Arquivos</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroConsultarDocumentos">Consultar Documentos</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroInformacoesAvancadas">Informações Avançadas</b-form-checkbox>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col lg="3">
        <b-card-header
          header="Assinatura Eletrônica"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox
                v-model="optionsLocal.FinanceiroAssinaturas"
                @change="marcarDesmarcarHandler('AssinaturaDigital')"
              >
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group class="mb-1">
              <b-form-checkbox v-model="optionsLocal.FinanceiroAssinaturasConsulta">Consultar</b-form-checkbox>
            </b-form-group>
            <b-form-group class="mb-1">
              <b-form-checkbox v-model="optionsLocal.FinanceiroAlterarAssinaturas">Alterar Assinaturas</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroAssinaturaDigital">Assinatura Digital</b-form-checkbox>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import Ripple from 'vue-ripple-directive'

  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  export default {
    components: {
      ToastificationContent,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        optionsLocal: this.generalData,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
      }
    },
    methods: {
      updateGeneralData() {
        this.$emit('update:generalData', JSON.parse(JSON.stringify(this.optionsLocal)))
      },
      CFinanceiro() {
        const value = this.optionsLocal.Financeiro

        ;(this.optionsLocal.FinanceiroContratosConsulta = value),
          (this.optionsLocal.FinanceiroContratosPendentes = value),
          (this.optionsLocal.FinanceiroContratosConsolidados = value),
          (this.optionsLocal.FinanceiroCancelarContrato = value),
          (this.optionsLocal.FinanceiroAnexarArquivos = value),
          (this.optionsLocal.FinanceiroExcluirArquivos = value),
          (this.optionsLocal.FinanceiroConsultarDocumentos = value),
          (this.optionsLocal.FinanceiroInformacoesAvancadas = value),
          this.updateGeneralData()
      },
      CAssinatura() {
        const value = this.optionsLocal.FinanceiroAssinaturas

        ;(this.optionsLocal.FinanceiroAssinaturasConsulta = value),
          (this.optionsLocal.FinanceiroAlterarAssinaturas = value),
          (this.optionsLocal.FinanceiroAssinaturaDigital = value),
          this.updateGeneralData()
      },
      marcarDesmarcarHandler(tipo) {
        if (tipo == 'AssinaturaDigital') {
          this.CAssinatura()
        }
        if (tipo == 'Financeiro') {
          this.CFinanceiro()
        }
      },
    },
  }
</script>

<style scoped>
  .text-lg {
    font-size: 1.3rem;
  }
</style>
